import React, { useEffect, useState,} from 'react';
import { connect } from '../data/connect';
import { withRouter, RouteComponentProps } from 'react-router';
import * as selectors from '../data/selectors';
import { IonText, IonRow, IonCol, IonToolbar, IonBackButton, IonButtons, IonPage, IonTitle, IonHeader, IonContent, IonLabel, IonCard, IonCardHeader, IonCardContent, IonCardTitle, IonCardSubtitle, IonIcon, IonButton, IonFooter} from '@ionic/react';
import { Feedback } from '../models/Feedback';
import { car, happyOutline, sadOutline, person } from 'ionicons/icons';
import './FeedbackDetail.css';
import { sendFeedbackStatus } from '../data/sync';
import { setServerMessage, setServerResStatus, refreshFeedbacks } from '../data/data/data.actions';

interface OwnProps extends RouteComponentProps { };

interface StateProps {
  feedback: Feedback;
};

interface DispatchProps {
  setServerMessage: typeof setServerMessage;
  setServerResStatus: typeof setServerResStatus;
  refreshFeedbacks: typeof refreshFeedbacks;
}

type FeedbackDetailProps = OwnProps & StateProps & DispatchProps;

const FeedbackDetail: React.FC<FeedbackDetailProps> = ({ feedback, setServerMessage, setServerResStatus, refreshFeedbacks }) => {
  const [isSending, setIsSending] = useState(false);

  const sendFeedbackStatusAct = async (nopol : string, date : string, message : string, nospbu : string, company_id : Number, state : string) => {

    // alert(JSON.stringify(data, null, 2));
    setIsSending(true);
    const {msg, responseStatus} = await sendFeedbackStatus({vehicle: nopol, date: date, message:message, nospbu:nospbu, company_id: company_id, state: state});
    await refreshFeedbacks();
    setIsSending(false);

    setServerMessage(msg);
    setServerResStatus(responseStatus);

  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/tabs/message"></IonBackButton>
          </IonButtons>
          <IonTitle>Message Detail</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding">
        <div>
        { feedback.AllMessage.map((message, index) => {
          return (
            <div key={index}>
              <div className="bubble-sent">
                <div className="sender-avatar avatar"></div>
                <div className="chat-bubble">
                  <div>
                    <p>~{message.sender}<span>{feedback.Shipment.SPBU }</span></p><br/>
                    {index == 0 && 
                      <div>
                        <p>
                          Shipment: {feedback.Shipment.Tanggal}
                        </p>
                        <p><IonIcon icon={car}/><strong>{feedback.Shipment.Nopol}</strong></p>
                        <p><IonIcon icon={person}/>{feedback.Shipment.Supir}</p>
                        <p><IonIcon icon={person}/>{feedback.Shipment.Kernet}</p>
                      </div>
                    }
                    <br/>
                    <p><strong>{message.pilihan}</strong></p>
                    <p>{message.message}</p>
                  </div>
                  <div className="bubble-arrow alt"></div>
                </div>
                
              </div>
              <div className="ion-padding-top bubble-received">
                <div className="responser-avatar avatar"></div>
                <div className="chat-bubble">
                  <div>
                    <p>~{message.responder}</p><br/>
                    <p>{message.response}</p>
                  </div>
                  <div className="bubble-arrow"></div>
                </div>
              </div>
            </div>
          );
        })}
        </div>
      </IonContent>
      
      { feedback.Status == 'draft' &&
        <IonFooter>
          <div style={{textAlign: "center"}}>
            <IonText>
              <h5><strong>Apakah Anda merasa Puas?(Ya/Tidak)</strong></h5>
            </IonText>
            <IonRow>
              <IonCol>
                <IonButton color="primary" fill="outline" onClick={() => { sendFeedbackStatusAct(feedback.Shipment.Nopol, feedback.Shipment.Tanggal2, feedback.Message.Pesan, feedback.Shipment.SPBU, feedback.Company, '3')}}>
                  <IonIcon icon={happyOutline} />Ya
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton color="danger" fill="outline" onClick={() => { sendFeedbackStatusAct(feedback.Shipment.Nopol, feedback.Shipment.Tanggal2, feedback.Message.Pesan, feedback.Shipment.SPBU, feedback.Company, '4')}}>
                  <IonIcon icon={sadOutline} />Tidak
                </IonButton>
              </IonCol>
            </IonRow>
          </div>
        </IonFooter>
      }

    </IonPage>
  );
};

export default connect<OwnProps, StateProps, DispatchProps>({
  mapStateToProps: (state, OwnProps) => ({
    feedback: selectors.getFeedback(state, OwnProps)
  }),
  mapDispatchToProps: {
    setServerMessage,
    setServerResStatus,
    refreshFeedbacks
  },
  component: withRouter(FeedbackDetail)
});
