import { Network } from '@capacitor/network';
import { getApiCheckLists, getApiDelivery, getApiDriverDetails, getApiFeedbackOfflineOptions, getApiFeedbacks, getApiJustify, getApiLossFormOffineData, getApiOrders, getApiTanks, getApiTransportLossAll, getApiVehicleDatails, sendApiChangedPassword, sendApiCheckLists, sendApiFeedback, sendApiProfile, sendApiTransportLossFormData, sendApiFeedbackStatus } from './api';
import { getStorageChecklists, getStorageDeliverys, getStorageDriverDetails, getStorageFeedbackOfflineOptions, getStorageFeedbacks, getStorageJustify, getStorageOrders, getStorageStack, getStorageTankOptions, getStorageTransportLossAll, getStorageTransportLossOffline, getStorageVehicleDatails, initStorageStack, saveStorageStack, setChecklists, setDeliverys, setDriverDetails, setFeedback, setFeedbackOfflineOptions, setJustify, setOrder, setTankOptions, setTransportLossAll, setTransportLossOffline, setVehicleDatails, updateStorageTransportLossOffline } from './storage';

export const getCurrentNetworkStatus = async () => {
  const status = await Network.getStatus();

  return status.connected;
};

export const getCheckLists = async (isofline : boolean) => {
  let checklist = undefined;
  if (isofline){
    checklist = await getStorageChecklists();
  }
  else {
    if (await getCurrentNetworkStatus()) {
      try {
        checklist = await getApiCheckLists();
        await setChecklists(checklist);
      } catch(err) {
        checklist = await getStorageChecklists();  
      }
    }
    else {
      checklist = await getStorageChecklists();
    }
  }
  return checklist;
}

export const getDelivery = async (isofline : boolean) => {
  let delivery = undefined;
  if (isofline){
    delivery = await getStorageDeliverys();
    if (delivery) {}
    else {
      if (await getCurrentNetworkStatus()) {
        try {
          delivery = await getApiDelivery()
          await setDeliverys(delivery);
        } catch(err) {
          console.log(`Display Error : \n ${err}`);
          delivery = await getStorageDeliverys();
        }
      }
    }
  }
  else {
    if (await getCurrentNetworkStatus()) {
      try {
        delivery = await getApiDelivery()
        await setDeliverys(delivery);
      } catch(err) {
        console.log(`Display Error : \n ${err}`);
        delivery = await getStorageDeliverys();
      }
    }
    else {
      delivery = await getStorageDeliverys();
    }
  }
  
  return delivery;
}

export const getFeedbacks = async (isofline : boolean) => {
  let feedbacks = undefined;
  if (isofline){
    feedbacks = await getStorageFeedbacks();
  }
  else {
    if (await getCurrentNetworkStatus()) {
      try {
        feedbacks = await getApiFeedbacks();
        await setFeedback(feedbacks);
      } catch(err) {
        feedbacks = await getStorageFeedbacks();  
      }
      
    }
    else {
      feedbacks = await getStorageFeedbacks();
    }
  }
  
  return feedbacks;
}

export const getJustify = async (isofline : boolean) => {
  let justify = undefined;
  if (isofline){
    justify = await getStorageJustify();
  }
  else {
    if (await getCurrentNetworkStatus()) {
      try {
        justify = await getApiJustify();
        await setJustify(justify);
      } catch(err) {
        justify = await getStorageJustify();  
      }
      
    }
    else {
      justify = await getStorageJustify();
    }
  }

  return justify;
}

export const getOrders = async (isofline : boolean) => {
  let order = undefined;
  if (isofline){
    order = await getStorageOrders();
  }
  else {
    if (await getCurrentNetworkStatus()) {
      try {
        order = await getApiOrders();
        await setOrder(order);
      } catch(err) {
        order = await getStorageOrders();
      }
    }
    else {
      order = await getStorageOrders();
    }
  }

  return order;
}

export const getTanks = async (isofline : boolean) => {
  let tank = undefined;
  if (isofline){
    tank = await getStorageTankOptions();
  }
  else {
    if (await getCurrentNetworkStatus()) {
      try {
        tank = await getApiTanks();
        await setTankOptions(tank);
      } catch(err) {
        tank = await getStorageTankOptions();
      }
      
    }
    else {
      tank = await getStorageTankOptions();
    }
  }

  return tank;
}

export const getTransportLossAll = async (isofline : boolean) => {
  let transportlossAll = undefined;
  if (isofline){
    transportlossAll = await getStorageTransportLossAll();
  }
  else {
    if (await getCurrentNetworkStatus()) {
      try {
        transportlossAll = await getApiTransportLossAll();
        await setTransportLossAll(transportlossAll);
      } catch(err) {
        transportlossAll = await getStorageTransportLossAll();
      }
    }
    else {
      transportlossAll = await getStorageTransportLossAll();
    }
  }

  return transportlossAll;
}

export const getLossFormOffineData = async (shipIds : [], isofline : boolean) => {
  let lossFormOfflineData = undefined;
  if (isofline){
    lossFormOfflineData = await getStorageTransportLossOffline();
  }
  else {
    if (await getCurrentNetworkStatus()) {
      try {
        lossFormOfflineData = await getApiLossFormOffineData(shipIds);
        await setTransportLossOffline(lossFormOfflineData);
      } catch(err) {
        lossFormOfflineData = await getStorageTransportLossOffline();  
      }
    }
    else {
      lossFormOfflineData = await getStorageTransportLossOffline();
    }
  }
  
  return lossFormOfflineData;
}

export const getFeedbackOfflineOptions = async (isofline : boolean) => {
  let feedbackOptions = undefined;
  if (isofline){
    feedbackOptions = await getStorageFeedbackOfflineOptions();
  }
  else {
    if (await getCurrentNetworkStatus()) {
      try {
        feedbackOptions = await getApiFeedbackOfflineOptions();
        await setFeedbackOfflineOptions(feedbackOptions);
      } catch(err) {
        feedbackOptions = await getStorageFeedbackOfflineOptions();  
      }
    }
    else {
      feedbackOptions = await getStorageFeedbackOfflineOptions();
    }
  }

  return feedbackOptions;
}

export const getDriverDetails = async (driverIDs : [], isofline : boolean) => {
  let driverDetails = undefined;
  if (isofline){
    driverDetails = await getStorageDriverDetails();
  }
  else {
    if (await getCurrentNetworkStatus()) {
      try {
        driverDetails = await getApiDriverDetails(driverIDs);
        await setDriverDetails(driverDetails);
      } catch(err) {
        driverDetails = await getStorageDriverDetails();  
      }
    }
    else {
      driverDetails = await getStorageDriverDetails();
    }
  }
  
  return driverDetails;
}

export const getVehicleDatails = async (vehicleIDs : [], isofline : boolean) => {
  let vehicleDetails = undefined;
  if (isofline){
    vehicleDetails =  await getStorageVehicleDatails();
  }
  else {
    if (await getCurrentNetworkStatus()) {
      try {
        vehicleDetails = await getApiVehicleDatails(vehicleIDs);
        await setVehicleDatails(vehicleDetails);
      } catch(err) {
        vehicleDetails =  await getStorageVehicleDatails();  
      }
    }
    else {
      vehicleDetails =  await getStorageVehicleDatails();
    }
  }
  
  return vehicleDetails;
}

export const sendFeedback = async (data: any) => {
  let msg = "";
  let responseStatus = "";
  if (await getCurrentNetworkStatus()) {
    try {
      const {message, status} = await sendApiFeedback(data);
      msg = message;
      responseStatus = status;
    } catch(err) {
      await saveStorageStack("feedback", data);
      msg = "Error is occured with Network, So Datas are saved to Storage temporarily"
      responseStatus = "E";
    }
  }
  else {
    await saveStorageStack("feedback", data);
    msg = "Datas are saved to Storage temporarily"
    responseStatus = "S";
  }
  return { msg, responseStatus };
}

export const sendFeedbackStatus = async (data: any) => {
  let msg = "";
  let responseStatus = "";
  if (await getCurrentNetworkStatus()) {
    try {
      const {message, status} = await sendApiFeedbackStatus(data);
      msg = message;
      responseStatus = status;
    } catch(err) {
      msg = "Error is occured with Network, So Datas are saved to Storage temporarily"
      responseStatus = "E";
    }
  }
  else {
    await saveStorageStack("feedback", data);
    msg = "Datas are saved to Storage temporarily"
    responseStatus = "S";
  }
  return { msg, responseStatus };
}

export const sendCheckLists = async (data: any) => {
  let msg = "";
  let responseStatus = "";
  if (await getCurrentNetworkStatus()) {
    try {
      const {message, status} = await sendApiCheckLists(data);
      msg = message;
      responseStatus = status;
    } catch(err) {
      await saveStorageStack("checklist", data);
      msg = "Error is occured with Network, So Datas are saved to Storage temporarily";
      responseStatus = "E";
    }
  }
  else {
    await saveStorageStack("checklist", data);
    msg = "Datas are saved to Storage temporarily";
    responseStatus = "S";
  }
  return { msg, responseStatus };
}

export const sendProfile = async (data: any) => {
  let msg = "";
  let responseStatus = "";
  if (await getCurrentNetworkStatus()) {
    try {
      const {message, status} = await sendApiProfile(data);
      msg = message;
      responseStatus = status;
    } catch(err) {
      await saveStorageStack("profile", data);
      msg = "Error is occured with Network, So Datas are saved to Storage temporarily";
      responseStatus = "E";
    }
  }
  else {
    await saveStorageStack("profile", data);
    msg = "Datas are saved to Storage temporarily";
    responseStatus = "S";
  }
  return { msg, responseStatus };
}

export const sendChangedPassword = async (data: any) => {
  let msg = "";
  let responseStatus = "";
  if (await getCurrentNetworkStatus()) {
    try {
      const {message, status} = await sendApiChangedPassword(data);
      msg = message;
      responseStatus = status;
    } catch(err) {
      msg = "Error is occured with Network, Retry later";
      responseStatus = "E";
    }
  }
  else {
    await saveStorageStack("profile", data);
    msg = "Offline now, Retry later";
    responseStatus = "E";
  }
  return { msg, responseStatus };
}

export const sendTransportLossFormData = async (data: any) => {
  let msg = "";
  let responseStatus = "";
  if (await getCurrentNetworkStatus()) {
    try {
      const {message, status} = await sendApiTransportLossFormData(data);
      msg = message;
      responseStatus = status;
    } catch(err) {
      await saveStorageStack("transportLoss", data);
      msg = "Error is occured with Network, So Datas are saved to Storage temporarily";
      responseStatus = "E";
    }
  }
  else {
    await saveStorageStack("transportLoss", data);
    msg = "Datas are saved to Storage temporarily"
    responseStatus = "S";
  }

  return { msg, responseStatus };
}

export const sendOfflineStackData = async () => {
  let success = true;
  const datas= await getStorageStack();
  let msg = "Datas are sent successfully";
  let responseStatus = "S";
  
  if (datas == null) return;

  try {
    Object.entries(datas).forEach(async ([key, value]) => {
      if (key === "profile") {
        console.log("send profile from offine stack");
        
      }
      else if (key === "feedback" && Array.isArray(value)) {
        value.map(async (feedback: any) => {
          console.log("send feedback from offine stack");

          try {
            const { status } = await sendApiFeedback(feedback);
          }
          catch(err) {
            throw Error();
          }
        });
      }
      else if (key === "transportLoss" && Array.isArray(value)) {
        value.map(async (data: any) => {
          console.log("send transportloss calculation data from offine stack");

          try {
            const { status } = await sendApiTransportLossFormData(data);
          }
          catch(err) {
            throw Error();
          }
        });
      }
      else if (key === "checklist" && Array.isArray(value)) {
        value.map(async (list: any) => {
          console.log("send checklist from offine stack");

          try {
            const { status } = await sendApiFeedback(list);
          }
          catch(err) {
            throw Error();
          }
        });
      }
    });
  } catch(err) {
    success = false;
  }

  if (success)
    initStorageStack();
}

export const getOfflineStackCount = async () => {
  let count = 0;

  const datas= await getStorageStack();
  
  if (datas == null) return 0;

  Object.entries(datas).forEach(async ([key, value]) => {
    if (key === "profile" && value !== "") {
      ++count;
    }
    else if (key === "feedback" && Array.isArray(value)) {
      count += value.length;
    }
    else if (key === "transportLoss" && Array.isArray(value)) {
      count += value.length;
    }
    else if (key === "checklist" && Array.isArray(value)) {
      count += value.length;
    }
  });

  return count;
}

export const cleanStorage = async () => {
  let delivery = {};
  let order = {};
  let feedbacks = {};
  let transportlossAll = {};
  let checklist = {};
  let tank = {};
  let justify = {};
  let lossFormOfflineData = {};
  let feedbackOptions = {};
  let driverDetails = {};
  let vehicleDetails = {};

  if (await getCurrentNetworkStatus()) {
    try {
      await setDeliverys(delivery);
      await setOrder(order);
      await setFeedback(feedbacks);
      await setTransportLossAll(transportlossAll);
      await setChecklists(checklist);
      await setTankOptions(tank);
      await setJustify(justify);
      await setTransportLossOffline(lossFormOfflineData);
      await setFeedbackOfflineOptions(feedbackOptions);
      await setDriverDetails(driverDetails);
      await setVehicleDatails(vehicleDetails);
      return true;
    } catch(err) {
      console.log(`Display Error : \n ${err}`); 
      return false;
    }
  }
  else {
    return false;
  }
}